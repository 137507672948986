import './App.css';
import MainScreen from './screen/MainScreen';

function App() {
  return (
    <div style={{backgroundColor: 'white'}}>
      <MainScreen/>
    </div>
  );
}

export default App;
