import React, { useState } from "react";
import NavCustom from "../components/NavCustom";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBFooter,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBTextArea,
} from "mdb-react-ui-kit";
import illustrationMain from "../assets/LOGO NOTARIA PABLO.png";
import illustrationFooter from "../assets/logo-name-white.png";
import "../App.css";
import ServiceCard from "../components/ServiceCard";
// Imports cards
import cardPoderesNotariales from "../assets/poderNotarial.jpg";
import cardEscriturasN from "../assets/escriturasNotariales.jpg";
import cardCreditosH from "../assets/creditosHipotecarios.jpg";
import cardTestamento from "../assets/testamento.png";
import cardTituloCV from "../assets/tituloCompraventa.jpg";
import cardActaCons from "../assets/actaConstitutiva.jpg";
// Import media
import Not4Pov from "../assets/Not4Pov.jpg";
// Import bg
import bg from "../assets/bg-intial.png";
// Api EmailJS
import emailjs from "@emailjs/browser";
import { Modal } from "react-bootstrap";

const MainScreen = () => {
  const mapUrl =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d941.7320943249541!2d-103.72517529598613!3d19.24195297714653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x84255aab927c4c8b%3A0x3d01570d20e2b871!2sNotar%C3%ADa%20P%C3%BAblica%20Cuatro!5e0!3m2!1ses-419!2smx!4v1711040584585!5m2!1ses-419!2smx";

  const [dataForm, setDataForm] = useState({
    name: "",
    email: "",
    msg: "",
  });

  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [msgModal, setMsgModal] = useState("");

  const handleChangeInputs = (e) => {
    const { name, value } = e.target;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_276d7du",
        "template_d0d6vrj",
        {
          from_name: dataForm.name,
          to_name: "N4COL",
          message: dataForm.msg,
          reply_to: dataForm.email,
          user_email: dataForm.email,
        },
        { publicKey: "_cK0clUjNek7QdBFJ" }
      )
      .then(
        () => {
          setShowModalConfirm(true);
          setMsgModal("Información correctamente enviada");
        },
        (error) => {
          setMsgModal(
            "Error al enviar la información, por favor intente más tarde."
          );
          setShowModalConfirm(true);
          console.log(error);
        }
      );
    setDataForm({
      name: "",
      email: "",
      msg: "",
    });
  };

  return (
    <>
      {/* Primera sección y navbar */}
      <Modal
        size="lg"
        centered
        show={showModalConfirm}
        onHide={() => setShowModalConfirm(false)}
      >
        <Modal.Header>
          <Modal.Title>Aviso</Modal.Title>
        </Modal.Header>
        <Modal.Body>{msgModal}</Modal.Body>
        <Modal.Footer className="mx-100">
          <MDBBtn color="success" onClick={() => setShowModalConfirm(false)}>
            OK
          </MDBBtn>
        </Modal.Footer>
      </Modal>
      <NavCustom />
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <MDBContainer className="animate__animated animate__bounceInUp ">
          <MDBRow className="vh-100">
            <MDBCol
              size="md"
              className="d-flex align-items-center justify-content-start "
            >
              <img
                src={illustrationMain}
                alt=""
                width="auto"
                height="130px"
                className=""
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>

      {/* Segunda sección */}
      <MDBContainer className="" id="servicios">
        <MDBRow className="text-center animate__animated animate__bounceInUp">
          <MDBCol size="md">
            <h2 style={{ color: "#f79633" }} className="mt-5">
              NUESTROS SERVICIOS
            </h2>
            <hr className="hr hr-blurry" />
            <p>
              Nuestros notarios expertos validan y legalizan adecuadamente su
              documentación para asegurarse de que cumple las normas legales.
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-5 ">
          <MDBCol size="md " animation="fade-in-up">
            <ServiceCard
              name={"Poderes Notariales"}
              img={cardPoderesNotariales}
            />
          </MDBCol>
          <MDBCol size="md">
            <ServiceCard name={"Escrituras Notariales"} img={cardEscriturasN} />
          </MDBCol>
          <MDBCol size="md">
            <ServiceCard name={"Créditos Hipotecarios"} img={cardCreditosH} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="md">
            <ServiceCard name={"Testamentos"} img={cardTestamento} />
          </MDBCol>
          <MDBCol size="md">
            <ServiceCard name={"Titulos Compraventa"} img={cardTituloCV} />
          </MDBCol>
          <MDBCol size="md">
            <ServiceCard name={"Actas Constitutivas"} img={cardActaCons} />
          </MDBCol>
        </MDBRow>
        <MDBRow className="p-4 text-center">
          <p>Y MÁS</p>
        </MDBRow>
      </MDBContainer>
      <MDBContainer id="nosotros">
        <MDBRow>
          <MDBCol className="text-center">
            <h2 style={{ color: "#f79633" }} className="mt-5">
              ACERCA DE NOTARÍA 4 COLIMA
            </h2>
            <hr className="hr hr-blurry" />
            <p>
              "Desde su fundación en 1941, Notaría 4 ha sido un pilar en la
              comunidad de Colima, ofreciendo servicios notariales de confianza
              y excelencia. A lo largo de los años, hemos construido relaciones
              sólidas con nuestros clientes, basadas en la integridad y el
              compromiso con la excelencia. Nuestra historia está marcada por el
              orgullo de servir a nuestra comunidad y estamos comprometidos a
              seguir haciéndolo en el futuro."
            </p>
            <p className="pt-3 fs-5" style={{ color: "#f79633" }}>
              NOTARIO TITULAR
            </p>
            <p className="fst-light fs-4 ">
              LIC. PABLO BERNARDO CASTAÑEDA DE LA MORA
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-4">
          <MDBCol
            size="md"
            className="d-flex align-items-center justify-content-center"
          >
            <img
              src={Not4Pov}
              alt=""
              width="auto"
              height="400px"
              className="img-fluid shadow-4 p-2"
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer id="contacto">
        <MDBRow>
          <MDBCol>
            <h2 style={{ color: "#f79633" }} className="mt-5">
              CONTACTO
            </h2>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="md">
            <hr className="hr" />
            <h5>DIRECCIÓN</h5>
            {/* <img
              src={Not4Loc}
              alt=""
              width="auto"
              height="200px"
              className=" shadow-5-strong rounded-3 "
            /> */}
            <iframe
              src={mapUrl}
              width="100%"
              height="360"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </MDBCol>
          <MDBCol size="md">
            <hr className="hr" />
            <h5>CONTACTANOS</h5>
            <MDBRow>
              <MDBCol size="md" className="mt-3">
                <MDBInput
                  label="Nombre"
                  type="text"
                  className="input"
                  name="name"
                  onChange={handleChangeInputs}
                  value={dataForm.name}
                />
              </MDBCol>
              <MDBCol size="md" className="mt-3">
                <MDBInput
                  name="email"
                  onChange={handleChangeInputs}
                  value={dataForm.email}
                  label="Correo"
                  type="email"
                  placeholder="ejemplo@email.com"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-3">
              <MDBCol center size="md">
                <MDBTextArea
                  label="Mensaje"
                  rows={9}
                  name="msg"
                  onChange={handleChangeInputs}
                  value={dataForm.msg}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mx-1">
              <MDBBtn
                className=" btn btn-warning mt-2"
                rounded
                onClick={handleSubmit}
              >
                Enviar
              </MDBBtn>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBFooter color="white" bgColor="dark" className="mt-5">
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span>Sigue conectado con nostros en nuestras redes sociales:</span>
          </div>
          <div>
            <a
              href="https://www.facebook.com/p/Notaria-4-Colima-100068056542629/?locale=es_LA&paipv=0&eav=AfYrONOzF-CCgeuS8iQzNbSDg1PWrjrYuaWPjysaNRElePMMNSeakQbQ8AiQ8Qa1srw&_rdr"
              className="me-4 text-reset"
            >
              <MDBIcon color="secondary" fab icon="facebook-f" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon color="secondary" fab icon="instagram" />
            </a>
          </div>
        </section>
        <section>
          <MDBContainer className="p-4">
            <MDBRow className="">
              <MDBCol
                className="d-flex align-items-center justify-content-center mb-3"
                size="md"
              >
                <img
                  src={illustrationFooter}
                  alt=""
                  width="auto"
                  height="100px"
                />
              </MDBCol>
              <MDBCol size="md">
                <h6 className="text-uppercase fw-bold mb-4">Información</h6>
                <p>
                  <MDBIcon color="secondary" icon="phone" className="me-2" />
                  312 313 11 08
                </p>
                <p>
                  <MDBIcon color="secondary" icon="home" className="me-2" />{" "}
                  Calle Ignacio Zaragoza 170, Centro, 28000 Colima, Col.
                </p>
                <p>
                  <MDBIcon color="secondary" icon="at" className="me-2" />
                  citas@notaria4colima.com
                </p>
                <p>
                  <MDBIcon
                    color="secondary"
                    icon="door-open"
                    className="me-2"
                  />{" "}
                  Lunes - Viernes: 9:00 a 18:00
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
        <section>
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
          >
            <MDBContainer>
              <a className="text-white" href="">
                © Notaría Pública No. 4, Colima, 2024
              </a>
            </MDBContainer>
          </div>
        </section>
      </MDBFooter>
    </>
  );
};

export default MainScreen;
