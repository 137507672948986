import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import React from "react";

const ServiceCard = ({ name, img }) => {
  return (
    <>
      <MDBCard className="mb-3 d-flex text-center justify-content-center shadow-5-strong">
        <MDBCardImage
          position="top"
          src={img}
          alt="..."
          style={{ width: "auto", height: "110px", objectFit: "cover" }}
        />
        <MDBCardBody>
          <MDBCardTitle className="p-3">{name}</MDBCardTitle>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

export default ServiceCard;
